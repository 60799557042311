import { Chart } from '../components/helpers';
import {createBarChart} from '../components/dashboard/utils/chart_utils';
import WidgetCardBox, { WidgetTitle } from '../components/dashboard/WidgetCardBox';
import { Col } from '@commonsku/styles';

function ProjectionChartAnalytics(props) {
    return(
        <WidgetCardBox colSizes={{xs: 12, sm: 12 ,lg: 6}}
        components={{Title: <WidgetTitle startText={'Projection'} />
          }}>
            <Col xs>
                <Chart
                type="Bar"
                height="300px"
                width="100%"
                isExportable={false}
                data ={props.chart_data}
                onChartReady={(chart,data)=>createBarChart(chart,{
                    isExportable:false,
                    data,
                    onClickColumn:props.onClickChartColumn ?  props.onClickChartColumn : null,
                    cursorLineX:false,
                    cursorLineY:false,
                    isLegendCheckbox:true,
                    legend:true,
                    formatNumber:'#a',
                    chartColorsAnalytics:['#02c0d0','#ffd302','#00D374','#DB0057'],
                    xAxes: [{
                    cursorTooltipEnabled:false,
                    category:'month_name',
                    title: 'Month',
                    showGrid: false,
                    showAxis: true,
                    showTitle: true,
                    showLabel: true,
                    disableGridTemplate:true,
                    maxWidth:200
                    }],
                    yAxes: [{
                    cursorTooltipEnabled: false,
                    showGrid: false,
                    showAxis: false,
                    showTitle: false,
                    disableGridTemplate:true,
                    showLabel:true,
                    renderOutside:true
                    }],
                    series: [
                    {
                    categoryX: 'month_name',
                    valueY: 'PRESALES',
                    name: "Pre-sales",
                    valueYShow : "totalPercent",
                    tooltipText: `\${valueY}`,
                    strokeWidth: 0,
                    fillPropertyOpacity: 'opacity',
                    fillColor : '#02c0d0',
                    stroke : '#02c0d0',
                    stacked:true
                    },{
                    categoryX: 'month_name',
                    valueY: 'SALES ORDER',
                    name: "Sales Orders",
                    tooltipText: `\${valueY}`,
                    strokeWidth: 0,
                    fillPropertyOpacity: 'opacity',
                    fillColor : '#ffd302',
                    stroke : '#ffd302',
                    stacked:true
                    },{
                    categoryX: 'month_name',
                    valueY: 'INVOICE',
                    name: "Invoices",
                    tooltipText: `\${valueY}`,
                    strokeWidth: 0,
                    fillPropertyOpacity: 'opacity',
                    fillColor : '#00D374',
                    stroke : '#00D374',
                    stacked:true
                    }
                    ] ,
                lineSeries:  [{
                categoryX: 'month_name',
                valueY: 'target',
                name: "INV Target",
                tooltipText: `\${valueY}`,
                strokeWidth: 3,
                stroke:'#DB0057',
                fillPropertyOpacity: 'opacity',
                }]
                })}
                >
                </Chart>
            </Col>
        </WidgetCardBox>
    );
}

export default ProjectionChartAnalytics;
