import React, {Component} from "react";
import MainSection from './MainSection';
import { Chart } from '../components/helpers';
import { connect } from 'react-redux';
import Header from "./Header";
import WidgetCardBox, { WidgetTitle } from '../components/dashboard/WidgetCardBox';
import { createDonutChartSales,createBarChart,createHorizontalBarChart} from '../components/dashboard/utils/chart_utils';
import { Theme, Col, Loading, colors,Row ,Box,LabeledInput, LabeledSelect, Select,
  TabBar,SizerWrapper,Tab, LabeledCheckbox, Popup} from '@commonsku/styles';
import { getOptions } from '../components/company-search/selectors';
import { createSelector } from 'reselect';
import { getClientDropdown } from '../selectors/dropdowns';
import { createLoadClientList } from '../actions/client';
import {updateFinanaceAnalyticsView, fetchFinanaceAnalytics, createLoadAccountStatuses,updateFinanceChart,fetchAnalyticsReport, createLoadTeamList, createAllLoadStatuses} from '../actions';
import { GlobalStyle } from './DashboardApp';
import DashboardPageTitle from '../components/dashboard/DashboardPageTitle';
import InvoicesVsTargetChart from "./InvoicesVsTargetChart";
import ProjectionChartAnalytics from "./ProjectionChart";
import SalesAndMarginByClient from "./SalesAndMarginByClientChart";
import { ANALYTICS_REPORT_TYPES, CHART_TYPE, getCombinedTargetData, getTimeStamp, month_names, timeOptions } from "./AnalyticsHelper";
import ProjectsTableWindowed from '../components/dashboard/ProjectsTableWindowed';
import { dateStr } from "../utils";
import withReducers from "../store/withReducers";
import statusReducer from '../redux/status';
import notesReducer from '../redux/notes';
import { NewProjectPopupButton } from "../components/project/NewProjectPopup";

const clientAllOption= [{ label:'All', value:''}];

const getClientOptions = createSelector(getClientDropdown,
    values => values.filter(v => v.client_name).map(v => ({ value: v.client_id, label: v.client_name }))
  );

class FinanceAnalyticsApp extends Component {
  state = {
    filters: {
      repType:{
          value: 'CLIENT', label: 'Client Rep'
      },
      client:{
          value:'', label:'All'
      },
      clientStatus :{
          value:'', label:'All'
      },
      repUser:{
        value:'',label:'All'
      },
      },
    selectedTab:'Sales',
    SUPPLIER_PO_FILTER:{
      value:'TM',label: 'This month'
    }
  }

  componentDidMount () {
    const { onLoadStatuses,onLoadClients,onLoadFinanceAnalytics, onLoadTeams, onLoadAccountStatuses} = this.props;
    onLoadStatuses();
    onLoadClients();
    onLoadFinanceAnalytics();
    onLoadTeams();
    onLoadAccountStatuses();
  }

  onClickInvoiceTargetChartColumn = (data, year_type = null) => {
    const {fetchPopUpData} = this.props;
    const report_type = ANALYTICS_REPORT_TYPES.INVOICES_VS_TARGET.type.finance;
    const route = ANALYTICS_REPORT_TYPES.INVOICES_VS_TARGET.route;
    const yr = year_type === 'total_next_year' ? data.next_year : data.year;
    const title = data.month + ' ' + yr;
    const month_number = (new Date(`${data.month} 1 ${data.year}`)).getMonth()+1;
    const end_of_month = dateStr(new Date(yr, month_number, 1));
    const start_of_month =  dateStr(new Date(yr, month_number - 1, 1));
    fetchPopUpData(route, report_type, {
      start_stamp: start_of_month,
      end_stamp: end_of_month,
      filters : JSON.stringify(this.state.filters)
    });
    this.setState({
      showPopup: {
      type: 'projects-list',
      title: ANALYTICS_REPORT_TYPES.INVOICES_VS_TARGET.title + title,
      report_type: report_type,
      },
  });
  };
  onClickProjectionChartColumn = (data,order_type =null) => {
    const {fetchPopUpData} = this.props;
    const report_type = ANALYTICS_REPORT_TYPES.PROJECTION.type.finance;
    const route = ANALYTICS_REPORT_TYPES.PROJECTION.route;
    const title = data.month_name;
    const month_number = (new Date(`${data.month_name} 1 ${data.year}`)).getMonth() + 1;
    const end_of_month = new Date(data.year, month_number, 1);
    end_of_month.setDate(end_of_month.getDate()-1);
    const start_of_month =  dateStr(new Date(data.year, month_number - 1, 1));
    fetchPopUpData(route, report_type, {
      order_type: order_type,
      start_stamp: start_of_month,
      end_stamp: dateStr(end_of_month),
      filters : JSON.stringify(this.state.filters)
    });
    this.setState({
      showPopup: {
      type: 'projects-list',
      title: ANALYTICS_REPORT_TYPES.PROJECTION.title + title,
      report_type: report_type,
      },
  });
  };
  onClickClientSlice = (data) => {
    if (data.sliceGrouperOther === true) {
      return;
    }
    const {fetchPopUpData} = this.props;
    const {selectedTab,filters} = this.state;
    const report_type = selectedTab === 'Sales' ? ANALYTICS_REPORT_TYPES.SALES_BY_CLIENT.type.finance : ANALYTICS_REPORT_TYPES.MARGIN_BY_CLIENT.type.finance;
    const route = selectedTab === 'Sales' ? ANALYTICS_REPORT_TYPES.SALES_BY_CLIENT.route : ANALYTICS_REPORT_TYPES.MARGIN_BY_CLIENT.route;
    const month_name = month_names[parseInt(data.month)-1];
    const title = data.client_name + " - " + month_name;
    const start_of_month = dateStr(new Date(parseInt(data.year),parseInt(data.month)-1,1 ));
    const end_of_month = dateStr(new Date(parseInt(data.year),parseInt(data.month),1));
    const subTitle = selectedTab === 'Sales' ? ANALYTICS_REPORT_TYPES.SALES_BY_CLIENT.title : ANALYTICS_REPORT_TYPES.MARGIN_BY_CLIENT.title;
    fetchPopUpData(route,report_type, {
      client_id:data.client_id,
      start_stamp : start_of_month,
      end_stamp : end_of_month,
      filters : JSON.stringify(filters)
    });
    this.setState({
      showPopup: {
        type: 'projects-list',
        title: subTitle + title,
        report_type: report_type
      }
    });
  }

  onChangeTimeFilter (value,filter) {
    const {onChangeChartFilterFinance} = this.props;
    var timeStamp =  getTimeStamp(value.value);
    this.setState({
      [filter] : value,
      filters: {...this.state.filters,
        supplierPoTimeFilter : {
        supplier_start_stamp:timeStamp.startStamp,
        supplier_end_stamp:timeStamp.endStamp
        }
      }
    });
    const v = CHART_TYPE[filter];
    const route = v.route;
    const state_placeholder = v.state_placeholder;
    onChangeChartFilterFinance (route,state_placeholder,{
      start_stamp: timeStamp.startStamp,
      end_stamp: timeStamp.endStamp,
      filters: JSON.stringify(this.state.filters)
    });
  }

  onChangeFilter (value,filter) {
    const { onChangeFilterDrop} = this.props;
    if (value === null) {
      value = {label: 'All', value: ''};
    }
    this.setState({filters: {...this.state.filters,[filter]:value}},() => {
      onChangeFilterDrop(this.state.filters);
    });
  }
  renderPopup() {
    if (!this.state.showPopup) {
      return null;
    }
    return (<Popup
      title={this.state.showPopup.title}
      onClose={() => {
       this.setState({showPopup:false});
      }}
      className={`popup ${this.state.showPopup.type}-analytics-popup`}
    >   {(this.state.showPopup.type === 'projects-list' ?
    <ProjectsTableWindowed
      {...this.state.showPopup}
      onSave={() => this.setState({showPopup:false})}
     loadReportTotal={reportType =>  this.setState({reportType:reportType})}
    />
  : null)}
      <br />
    </Popup>
  );}
  render() {
    const originalStyle = {
      top: 0,
      left: 0,
      zIndex: 100,
      width: '100%',
      minHeight: '100%',
      background: 'rgb(237, 242, 245)',
      position: 'absolute',
      paddingBottom: '2em'
      };

    const {invoice_data,invoice_target_data, popups,filterOptions, sales_by_client_data,
     margin_by_client_data,supplier_po_data ,projection_data,finance_data,identity} = this.props;
    const {filters,selectedTab,SUPPLIER_PO_FILTER} = this.state;

    const margin_sales_chart_data = {
      sales_by_client_data: sales_by_client_data,
      margin_by_client_data: margin_by_client_data
    };

    let tabs=[
      {
        label:'Sales by Client',
        key:'Sales',
      }];

    tabs.push({
    label:'Margin by Client',
    key:'Margin',
    });
    return(
      <div>
        <Theme>
        <GlobalStyle />
          <Header></Header>
          <MainSection popups={popups} style={{background:'rgb(237, 242, 245)'}}>
            <div className="main-content csku-styles" style={{ ...originalStyle, top: this.state.top_margin, marginTop: 0 }}>
              <Row style={{ marginTop: 0, marginBottom: 20, }}>
                <Col xs sm={6} padded>
                {this.renderPopup()}
                <DashboardPageTitle title={"Finance Analytics"} />
                </Col>
                <Col xs
                  sm={6}
                  padded
                  end={1}
                ><NewProjectPopupButton /></Col>
              </Row>
              <Row style={{placeContent:'flex-start'}}>
                <Col padded xl={6} sm={6} xs={8}>
                  <LabeledSelect
                    label="Client"
                    name="client"
                    value={filters.client}
                    options={clientAllOption.concat(filterOptions.clients)}
                    onChange ={(e)=>this.onChangeFilter(e,'client')}
                    menuShouldScrollIntoView={false}
                    className="csku-select-v3"
                    isClearable = {!(filters.client.value === '')}
                  />
                </Col>
                <Col padded xl={6} sm={6} xs={8}>
                  <LabeledSelect
                    label="Client Status"
                    name="clientStatus"
                    value={filters.clientStatus}
                    options={filterOptions.statuses_all}
                    onChange={(e)=>this.onChangeFilter(e,'clientStatus')}
                    menuShouldScrollIntoView={false}
                    className="csku-select-v3"
                    isClearable = {!(filters.clientStatus.value === '')}
                  />
                </Col>
              </Row>
              {Object.keys(finance_data).length ?
              <>
                <Row style={{placeContent:'flex-start'}}>
                  <WidgetCardBox  colSizes={{xs: 12, lg: 12}}  components={{
                  Title: <WidgetTitle />
                  }}>
                    <Col xs >
                      <TabBar style={{ marginLeft: '1rem', marginBottom: '1rem' }}>
                      {tabs.map((tab, index) => <Tab
                      size={tabs.length}
                      selected={tab.key === this.state.selectedTab}
                      onClick={() => {this.setState({ selectedTab: tab.key });}}>
                      {tab.label}
                      </Tab>)}
                      </TabBar>
                      <SalesAndMarginByClient chart_data ={margin_sales_chart_data}
                      selectedTab = {selectedTab} onClickClientSlice = {this.onClickClientSlice}
                      />
                    </Col>
                  </WidgetCardBox>
                </Row>
                <Row style={{placeContent:'flex-start' }}>
                  <Col style={{marginTop: '-15px'}}>
                    <InvoicesVsTargetChart chart_data={identity.show_invoice_targets == true ? invoice_target_data : invoice_data}
                    chart_height='300px'
                    onClickChartColumn = {this.onClickInvoiceTargetChartColumn}
                    />
                    <ProjectionChartAnalytics chart_data = {projection_data}
                    onClickChartColumn = {this.onClickProjectionChartColumn}/>
                  </Col>
                  <WidgetCardBox colSizes={{xs: 12, sm:12, lg: 6}}  components={{
                  Title:<Row style={{ justifyContent:'space-between'}}>
                  <WidgetTitle startText={'Top 10 Suppliers'} />
                  <Col padded xl={3} sm={6} xs={6}
                  style={{marginTop:'-15px'}}>
                  <LabeledSelect
                  name="SUPPLIER_PO_FILTER"
                  value={SUPPLIER_PO_FILTER}
                  options={timeOptions}
                  onChange ={(e)=>this.onChangeTimeFilter(e,'SUPPLIER_PO_FILTER')}
                  menuShouldScrollIntoView={false}
                  className="csku-select-v3"
                  />
                  </Col>
                  </Row>
                  }}>

                  <Col xs >
              <Chart
                type="Bar"
                height="700px"
                width="100%"
                isExportable={false}
                data={supplier_po_data}
                onChartReady={(chart, data) => createHorizontalBarChart(chart, {
                data,
                legend:false,
                isExportable:false,
                cursorLineX:false,
                cursorLineY:false,
                formatNumber:'#a',
                xAxes: [{
                  cursorTooltipEnabled: false,
                  category: 'division_name',
                  showGrid: false,
                  showAxis: true,
                  showTitle: false,
                  showLabel: false,
                  disableGridTemplate:true
                }],
                yAxes: [{
                  cursorTooltipEnabled:false,
                  title: '$PO',
                  showGrid: false,
                  showAxis: true,
                  showTitle: true,
                  disableGridTemplate:true,
                  showLabel: true,
                }],
                series: [
                  /*{
                  categoryX: 'division_name',
                  valueY: 'previous_po_total',
                  name:'Last Month',
                  tooltipText: `[#fff font-size: 15px]{division_name} in {previous_month}:\n[/][#fff font-size: 20px]{previous_po_total}[/] [#fff]{additional}[/]`,
                  strokeWidth: 0,
                  fillPropertyOpacity: 'opacity',
                  fillColor : '#00B9D5',
                  stroke : '#00B9D5',

                },*/
                {
                  categoryX: 'division_name',
                  valueY: 'po_total',
                  name:"PO",
                  tooltipText: `[#fff font-size: 15px]{categoryY}:\n[/][#fff font-size: 20px]{valueX}[/] [#fff]{additional}[/]`,
                  strokeWidth: 0,
                  fillPropertyOpacity: 'opacity',
                  fillColor : '#00B9D5',
                  stroke : '#00B9D5',
                  isInsideBarLabel:true,
                  insideBarLabelText:`{division_name}`

                }]
              })}
              >

              </Chart>

            </Col>
                  </WidgetCardBox>
                </Row>
              </> :
              <>
                <Loading />
                <p style={{
                  padding: 10,
                  margin: 0,
                  textAlign: 'center',
                  color: '#00A0B6',
                  fontSize: '1.3rem',
                }}>Loading...</p>
              </>}
            </div>
          </MainSection>
        </Theme>
      </div>
    );
  }
}

const mapStateToProps = (state,ownProps)=> {
    const invoice_data = state.entities.finance_analytics.invoice_results;
    const target_data = state.entities.finance_analytics.sales_target_results;
  return {
    identity: state.identity,
    finance_data : state.entities.finance_analytics,
    invoice_data:invoice_data,
    invoice_target_data: getCombinedTargetData(invoice_data,target_data),
    sales_by_client_data:state.entities.finance_analytics.client_sales_results,
    margin_by_client_data: state.entities.finance_analytics.client_margin_results,
    supplier_po_data: state.entities.finance_analytics.supplier_po_results,
    projection_data: state.entities.finance_analytics.projection_results,
    filterOptions :{
    reps: getOptions(state.dropdowns.users, 'user_id', (v) => (v.contact_first_name + ' ' + v.contact_last_name)),
    reps_all: getOptions(state.dropdowns.users, 'user_id', (v) => (v.contact_first_name + ' ' + v.contact_last_name), false, [{ label: 'All', value: '' }]),
    statuses_all: getOptions(state.dropdowns.account_statuses, 'account_status_id', 'account_status_name', false, [{ label: 'All', value: '' }, { label: 'None', value: 'null' }]) ,
    clients : getClientOptions(state)
  }
  };

};

const mapDispatchToProps = dispatch => ({
  onLoadAccountStatuses:  (company_type = 'CLIENT') => {
    dispatch(createLoadAccountStatuses(company_type));
  },
  onLoadStatuses: () => {
    dispatch(createAllLoadStatuses({}));
  },
  onLoadClients: ()=>{
    dispatch(createLoadClientList({
      'order_by': 'latest_use',
      'order_dir': 'DESC',
    }));
  },
  onLoadTeams: ()=> {
    dispatch(createLoadTeamList({team_type: 'GROUP'}));
  },
  onChangeFilterDrop: (filters) => {
    dispatch(updateFinanaceAnalyticsView(filters));
  },
  onLoadFinanceAnalytics: () => {
    dispatch(fetchFinanaceAnalytics());
  },
  onChangeChartFilterFinance: (route,type,obj) =>{
    dispatch(updateFinanceChart(route,type,obj));
  },
  fetchPopUpData: (route,type,obj) => {
    dispatch(fetchAnalyticsReport(route,type,obj));
  }
});

export default withReducers(connect(mapStateToProps,mapDispatchToProps)(FinanceAnalyticsApp),{
  status: statusReducer,
  notes: notesReducer
});
